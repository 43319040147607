import React from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from '../../components/layout-en' 
import Head from '../../components/head'
import '../../styles/article.css'
//import { OutboundLink } from "gatsby-plugin-google-analytics"
import ScrollProgressRead from 'react-scroll-progress-read';
import { globalHistory } from "@reach/router"
import ReactMarkdown from "react-markdown"
import GooglePlay from '../../images/google-store.png'

import Collapsible from 'react-collapsible'
import AdSense from 'react-adsense';
import AppBanner from 'smart-app-banner-react';


import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";

import {
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share";

const appInfo = {
  imageUrl:
    'https://trello-attachments.s3.amazonaws.com/6049cb0286e0005586d131b9/115x115/8400e532900784bdb5b5ce37ec75d0f1/DokterTaniaLogo-Web2.png',
  name: 'Dokter Tania',
  publisher: 'Sahabat Bertani Anda',
  infoText: 'Gratis - di Google Play',
  linkButtonText: 'Download',
  linkUrl:
    'https://drtania.page.link/download'
}


  //ReactMarkdown accepts custom renderers
  const renderers = {
    //This custom renderer changes how images are rendered
    //we use it to constrain the max width of an image to its container
    image: ({
        alt,
        src,
        title,
    }) => (
        <img 
            alt={alt} 
            src={src} 
            title={title} 
            style={{ maxWidth: 500 }}  />
    ),
  };

class ArticleTemplate extends React.Component{
  constructor(props){
    super(props);
  }

  render(){
    const renderRelatedArticleCard = () => {
      return this.props.data.relatedArticles.edges.map(relatedArticle => {
        return( 
          <div key = {relatedArticle.node.id} className = "related-article-card">
            {relatedArticle.node.cover.map(cover_data => (
                <Link key = {cover_data.id} to = {`/blog/${relatedArticle.node.article_category.category_name}/${relatedArticle.node.category.category_name}/${relatedArticle.node.slug}`}><img src = {`${cover_data.url}`} alt = "article-image" width="100%" className = "related-article-card-image"/></Link>
            ))}
            <div className = "related-article-card-info">
              
              <Link to = {`/blog/${relatedArticle.node.article_category.category_name}/${relatedArticle.node.category.category_name}/${relatedArticle.node.slug}`} className = "related-article-card-title">{relatedArticle.node.title}</Link>
              <div className = "related-article-card-footer">
                <div className = "related-article-card-category">{relatedArticle.node.article_category.category_name}</div>
                <div className = "related-article-card-category">{relatedArticle.node.category.category_name}</div>
                <div className = "related-article-card-create-date">{relatedArticle.node.published_date}</div>
              </div>
            </div>
          </div>
        )
      });
    }

    return(
      <Layout>
        <Head
          title = {this.props.data.thisArticle.title}
          quote = {this.props.data.thisArticle.subtitle}/>
        <div className = "scroll-progress-bar">
          <ScrollProgressRead 
            backgroundColor="#FFFFFF"
            //backgroundColor="#CCC"
            target = "article-page-header-body-container"
            barColor="#385723"
            />  
        </div>

        <div className = "article-page-container">
          <AppBanner
              appInfo={appInfo}
              position='bottom'
              onClose={() => {
                console.log('onClose called')
              }}
              hidden={false}
              style={{
                zIndex: 10
              }}
          />

          <div className = "article-page-header-body-container">
            <div className = "article-header">
              <div className = "article-header-top">
                <div className = "tag-container">
                  <Link to = {`/blog/${this.props.data.thisArticle.article_category.category_name}`} className = "tag">
                    {this.props.data.thisArticle.article_category.category_name}
                  </Link>
                  <Link to = {`/blog/${this.props.data.thisArticle.article_category.category_name}`} className = "tag">
                    {this.props.data.thisArticle.category.category_name}
                  </Link>
                </div>
                <div className = "article-title">{this.props.data.thisArticle.title}</div>
                <div className = "article-Intro">{this.props.data.thisArticle.subtitle}</div> 
              </div>

              <div className = "article-divider"/>              
              <div className = "article-author-container">
              {this.props.data.thisArticle.contributor &&
                  <div className = "article-author">Oleh : <span>{this.props.data.thisArticle.contributor.name}</span></div>
                }
                <div className = "article-information">
                  <div className = "article-create-date">{this.props.data.thisArticle.createdAt}</div>
                  <div className = "article-long">
                    <li>{Math.ceil((this.props.data.thisArticle.content.length/10)/60)} Minute</li>
                  </div>
                  <div className = "box-neurafarm-link">
                    <FacebookShareButton 
                      url={`https://www.neurafarm.com${globalHistory.location.pathname}`}
                      quote={this.props.data.thisArticle.subtitle}
                      className = "link-logo"
                      hashtag="#BlogTania">
                    <FacebookIcon size={20} />
                    </FacebookShareButton>

                    <WhatsappShareButton
                      url={`https://www.neurafarm.com${globalHistory.location.pathname}`}
                      title={this.props.data.thisArticle.title}
                      separator=":: "
                      className = "link-logo"
                      >
                      <WhatsappIcon size={20} />
                    </WhatsappShareButton>

                    <LinkedinShareButton
                      url={`https://www.neurafarm.com${globalHistory.location.pathname}`}
                      title={this.props.data.thisArticle.title}
                      summary = {this.props.data.thisArticle.subtitle}
                      source = "Neurafarm"
                      className = "link-logo"
                      >
                      <LinkedinIcon size={20} />
                    </LinkedinShareButton>

                    <TwitterShareButton
                      url={`https://www.neurafarm.com${globalHistory.location.pathname}`}
                      title={this.props.data.thisArticle.title}
                      hashtag="#BlogTania"
                      className = "link-logo"
                      >
                      <TwitterIcon size={20} />
                    </TwitterShareButton>

                  </div>
                </div>
              </div>
            </div>
            
            
            
            <div className = "article-body">
              <div className = "article-image-container">
                {this.props.data.thisArticle.cover.map(cover_data => (
                    <div>
                      <img className = "article-image" src = {`${cover_data.url}`} alt = "article-image" width="100%"></img>
                      <div className = "article-image-source">
                        <p>Sumber Gambar: {`${this.props.data.thisArticle.cover_source}`}</p>
                      </div>
                    </div>
                ))}
              </div>  

              {this.props.data.thisArticle.read_more &&
                <div className = "article-read-more-container" id = "article-read-more">
                  <div className = "article-read-more-title">Baca Juga</div>
                  <ReactMarkdown source =  {this.props.data.thisArticle.read_more}/>
                </div>
              }


              <div className = "article-paragraph" id = "article-content">
                <ReactMarkdown 
                  source =  {this.props.data.thisArticle.content}
                  renderers={renderers}
                
                />
              </div>

              <div className = "article-cta">
                  <div className = "article-cta-text">
                      Ingin tingkatkan panen? Download aplikasi Dokter Tania sekarang
                  </div>
                  <div className = "article-cta-logo">
                      <a href="https://drtania.page.link/download"><img id = "article-icon" src = {GooglePlay}/></a> 
                  </div>
              </div>


              {this.props.data.thisArticle.references &&
                <div className = "article-references-container" id = "article-read-more">
                  <Collapsible trigger="Lihat Referensi">
                    <div className="content">
                      <ReactMarkdown source =  {this.props.data.thisArticle.references}/>
                    </div>
                  </Collapsible>
                  
                </div>
              }


              <div className = "article-body-divider" />

              <div className = "article-body-author">
                
                {this.props.data.thisArticle.contributor &&
                  <div className = "article-author">Oleh : <span>{this.props.data.thisArticle.contributor.name}</span></div>
                }
                <div className = "box-neurafarm-link">
                  <FacebookShareButton 
                    url={`https://www.neurafarm.com${globalHistory.location.pathname}`}
                    quote={this.props.data.thisArticle.subtitle}
                    className = "link-logo"
                    hashtag="#BlogTania">
                  <FacebookIcon size={20} />
                  </FacebookShareButton>

                  <WhatsappShareButton
                    url={`https://www.neurafarm.com${globalHistory.location.pathname}`}
                    title={this.props.data.thisArticle.title}
                    separator=":: "
                    className = "link-logo"
                    >
                    <WhatsappIcon size={20} />
                  </WhatsappShareButton>

                  <LinkedinShareButton
                    url={`https://www.neurafarm.com${globalHistory.location.pathname}`}
                    title={this.props.data.thisArticle.title}
                    summary = {this.props.data.thisArticle.subtitle}
                    source = "Neurafarm"
                    className = "link-logo"
                    >
                    <LinkedinIcon size={20} />
                  </LinkedinShareButton>

                  <TwitterShareButton
                    url={`https://www.neurafarm.com${globalHistory.location.pathname}`}
                    title={this.props.data.thisArticle.title}
                    hashtag="#BlogTania"
                    className = "link-logo"
                    >
                    <TwitterIcon size={20} />
                  </TwitterShareButton>  
                </div>
              </div>

              <div className = "article-body-divider" />
            </div>
          </div>

          <AdSense.Google
            client='ca-pub-1789574727061508'
            slot='3985421783'
            style={{ display: 'block' }}
            layout='in-article'
            format='fluid'
          />
        
          <div className = "article-footer">
            <div className = "article-footer-title">Artikel Terkait untuk Kamu</div>
            {renderRelatedArticleCard()}
          </div>

          <AdSense.Google
            client='ca-pub-1789574727061508'
            slot='1976838563'
            style={{ display: 'block' }}
            format='auto'
            responsive='true'
          />
        </div>
      </Layout>
    )
  }
}

export default ArticleTemplate

export const query = graphql`
  query ArticleTemplate($id: String, $tag: String) {
    thisArticle : strapiArticles(id: {eq: $id}) {
      title
      subtitle
      content
      createdAt(formatString: "DD MMMM YYYY")
      cover_source
      read_more
      contributor {name}
      references
      cover {
        id
        url
      }
      category {
          id
          category_name
      }
      
      article_category {
        category_name
      }
    }

    relatedArticles : allStrapiArticles(sort: {fields: published_date, order: ASC}, limit: 2, filter: {category: {category_name: {eq: $tag}}, id: {ne: $id}}) {
      edges {
        node {
          id
          title
          subtitle
          slug
          cover {
            id
            url
          }
          category {
            category_name
          }
          article_category {
            category_name
          }
          published_date(formatString: "DD MMMM YYYY")
        }
      }
    }

    
  }
`